export const regions = ["All", "Africa", "Asia", "Australia", "Central America", "Europe", "North America", "South America"]

export const locationData = [{
	"name": "Afghanistan",
	"capital": "Kabul",
	"lat": "34.51666667",
	"lng": "69.183333",
	"code": "AF",
	"continent": "Asia",
	"calling_code": 93,
	"tld": ".af",
	"landlocked": "1",
	"population": 37172386,
	"area": 652090
}, {
	"name": "Aland Islands",
	"capital": "Mariehamn",
	"lat": "60.116667",
	"lng": "19.9",
	"code": "AX",
	"continent": "Europe"
}, {
	"name": "Albania",
	"capital": "Tirana",
	"lat": "41.31666667",
	"lng": "19.816667",
	"code": "AL",
	"continent": "Europe",
	"calling_code": 355,
	"tld": ".al",
	"landlocked": "0",
	"population": 2866376,
	"area": 28748
}, {
	"name": "Algeria",
	"capital": "Algiers",
	"lat": "36.75",
	"lng": "3.05",
	"code": "DZ",
	"continent": "Africa",
	"calling_code": 213,
	"tld": ".dz",
	"landlocked": "0",
	"population": 42228429,
	"area": 2381741
}, {
	"name": "American Samoa",
	"capital": "Pago Pago",
	"lat": "-14.26666667",
	"lng": "-170.7",
	"code": "AS",
	"continent": "Australia",
	"calling_code": 1684,
	"tld": ".as",
	"landlocked": "0",
	"population": 55465,
	"area": 199
}, {
	"name": "Andorra",
	"capital": "Andorra la Vella",
	"lat": "42.5",
	"lng": "1.516667",
	"code": "AD",
	"continent": "Europe",
	"calling_code": 376,
	"tld": ".ad",
	"landlocked": "1",
	"population": 77006,
	"area": 468
}, {
	"name": "Angola",
	"capital": "Luanda",
	"lat": "-8.833333333",
	"lng": "13.216667",
	"code": "AO",
	"continent": "Africa",
	"calling_code": 244,
	"tld": ".ao",
	"landlocked": "0",
	"population": 30809762,
	"area": 1246700
}, {
	"name": "Anguilla",
	"capital": "The Valley",
	"lat": "18.21666667",
	"lng": "-63.05",
	"code": "AI",
	"continent": "North America",
	"calling_code": 1264,
	"tld": ".ai",
	"landlocked": "0",
	"population": 15094,
	"area": 96
}, {
	"name": "Antarctica",
	"capital": "N/A",
	"lat": "0",
	"lng": "0",
	"code": "AQ",
	"continent": "Antarctica",
	"calling_code": null,
	"tld": ".aq",
	"landlocked": "0",
	"population": 1106,
	"area": 13120000
}, {
	"name": "Antigua and Barbuda",
	"capital": "Saint John’s",
	"lat": "17.11666667",
	"lng": "-61.85",
	"code": "AG",
	"continent": "North America",
	"calling_code": 1268,
	"tld": ".ag",
	"landlocked": "0",
	"population": 96286,
	"area": 442
}, {
	"name": "Argentina",
	"capital": "Buenos Aires",
	"lat": "-34.58333333",
	"lng": "-58.666667",
	"code": "AR",
	"continent": "South America",
	"calling_code": 54,
	"tld": ".ar",
	"landlocked": "0",
	"population": 44494502,
	"area": 2780400
}, {
	"name": "Armenia",
	"capital": "Yerevan",
	"lat": "40.16666667",
	"lng": "44.5",
	"code": "AM",
	"continent": "Europe",
	"calling_code": 374,
	"tld": ".am",
	"landlocked": "1",
	"population": 2951776,
	"area": 29800
}, {
	"name": "Aruba",
	"capital": "Oranjestad",
	"lat": "12.51666667",
	"lng": "-70.033333",
	"code": "AW",
	"continent": "North America",
	"calling_code": 297,
	"tld": ".aw",
	"landlocked": "0",
	"population": 105845,
	"area": 193
}, {
	"name": "Australia",
	"capital": "Canberra",
	"lat": "-35.26666667",
	"lng": "149.133333",
	"code": "AU",
	"continent": "Australia",
	"calling_code": 61,
	"tld": ".au",
	"landlocked": "0",
	"population": 24982688,
	"area": 7741220
}, {
	"name": "Austria",
	"capital": "Vienna",
	"lat": "48.2",
	"lng": "16.366667",
	"code": "AT",
	"continent": "Europe",
	"calling_code": 43,
	"tld": ".at",
	"landlocked": "1",
	"population": 8840521,
	"area": 83859
}, {
	"name": "Azerbaijan",
	"capital": "Baku",
	"lat": "40.38333333",
	"lng": "49.866667",
	"code": "AZ",
	"continent": "Europe",
	"calling_code": 994,
	"tld": ".az",
	"landlocked": "1",
	"population": 9939800,
	"area": 86600
}, {
	"name": "Bahamas",
	"capital": "Nassau",
	"lat": "25.08333333",
	"lng": "-77.35",
	"code": "BS",
	"continent": "North America",
	"calling_code": 1242,
	"tld": ".bs",
	"landlocked": "0",
	"population": 385640,
	"area": 13878
}, {
	"name": "Bahrain",
	"capital": "Manama",
	"lat": "26.23333333",
	"lng": "50.566667",
	"code": "BH",
	"continent": "Asia",
	"calling_code": 973,
	"tld": ".bh",
	"landlocked": "0",
	"population": 1569439,
	"area": 694
}, {
	"name": "Bangladesh",
	"capital": "Dhaka",
	"lat": "23.71666667",
	"lng": "90.4",
	"code": "BD",
	"continent": "Asia",
	"calling_code": 880,
	"tld": ".bd",
	"landlocked": "0",
	"population": 161356039,
	"area": 143998
}, {
	"name": "Barbados",
	"capital": "Bridgetown",
	"lat": "13.1",
	"lng": "-59.616667",
	"code": "BB",
	"continent": "North America",
	"calling_code": 1246,
	"tld": ".bb",
	"landlocked": "0",
	"population": 286641,
	"area": 430
}, {
	"name": "Belarus",
	"capital": "Minsk",
	"lat": "53.9",
	"lng": "27.566667",
	"code": "BY",
	"continent": "Europe",
	"calling_code": 375,
	"tld": ".by",
	"landlocked": "1",
	"population": 9483499,
	"area": 207600
}, {
	"name": "Belgium",
	"capital": "Brussels",
	"lat": "50.83333333",
	"lng": "4.333333",
	"code": "BE",
	"continent": "Europe",
	"calling_code": 32,
	"tld": ".be",
	"landlocked": "0",
	"population": 11433256,
	"area": 30518
}, {
	"name": "Belize",
	"capital": "Belmopan",
	"lat": "17.25",
	"lng": "-88.766667",
	"code": "BZ",
	"continent": "Central America",
	"calling_code": 501,
	"tld": ".bz",
	"landlocked": "0",
	"population": 383071,
	"area": 22696
}, {
	"name": "Benin",
	"capital": "Porto-Novo",
	"lat": "6.483333333",
	"lng": "2.616667",
	"code": "BJ",
	"continent": "Africa",
	"calling_code": 229,
	"tld": ".bj",
	"landlocked": "0",
	"population": 11485048,
	"area": 112622
}, {
	"name": "Bermuda",
	"capital": "Hamilton",
	"lat": "32.28333333",
	"lng": "-64.783333",
	"code": "BM",
	"continent": "North America",
	"calling_code": 1441,
	"tld": ".bm",
	"landlocked": "0",
	"population": 63973,
	"area": 53
}, {
	"name": "Bhutan",
	"capital": "Thimphu",
	"lat": "27.46666667",
	"lng": "89.633333",
	"code": "BT",
	"continent": "Asia",
	"calling_code": 975,
	"tld": ".bt",
	"landlocked": "1",
	"population": 754394,
	"area": 47000
}, {
	"name": "Bolivia",
	"capital": "La Paz",
	"lat": "-16.5",
	"lng": "-68.15",
	"code": "BO",
	"continent": "South America",
	"calling_code": 591,
	"tld": ".bo",
	"landlocked": "1",
	"population": 11353142,
	"area": 1098581
}, {
	"name": "Bosnia and Herzegovina",
	"capital": "Sarajevo",
	"lat": "43.86666667",
	"lng": "18.416667",
	"code": "BA",
	"continent": "Europe",
	"calling_code": 387,
	"tld": ".ba",
	"landlocked": "0",
	"population": 3323929,
	"area": 51197
}, {
	"name": "Botswana",
	"capital": "Gaborone",
	"lat": "-24.63333333",
	"lng": "25.9",
	"code": "BW",
	"continent": "Africa",
	"calling_code": 267,
	"tld": ".bw",
	"landlocked": "1",
	"population": 2254126,
	"area": 581730
}, {
	"name": "Brazil",
	"capital": "Brasilia",
	"lat": "-15.78333333",
	"lng": "-47.916667",
	"code": "BR",
	"continent": "South America",
	"calling_code": 55,
	"tld": ".br",
	"landlocked": "0",
	"population": 209469333,
	"area": 8547403
}, {
	"name": "British Indian Ocean Territory",
	"capital": "Diego Garcia",
	"lat": "-7.3",
	"lng": "72.4",
	"code": "IO",
	"continent": "Africa",
	"calling_code": 246,
	"tld": ".io",
	"landlocked": "0",
	"population": 0,
	"area": 78
}, {
	"name": "British Virgin Islands",
	"capital": "Road Town",
	"lat": "18.41666667",
	"lng": "-64.616667",
	"code": "VG",
	"continent": "North America",
	"area": 151
}, {
	"name": "Brunei Darussalam",
	"capital": "Bandar Seri Begawan",
	"lat": "4.883333333",
	"lng": "114.933333",
	"code": "BN",
	"continent": "Asia"
}, {
	"name": "Bulgaria",
	"capital": "Sofia",
	"lat": "42.68333333",
	"lng": "23.316667",
	"code": "BG",
	"continent": "Europe",
	"calling_code": 359,
	"tld": ".bg",
	"landlocked": "0",
	"population": 7025037,
	"area": 110994
}, {
	"name": "Burkina Faso",
	"capital": "Ouagadougou",
	"lat": "12.36666667",
	"lng": "-1.516667",
	"code": "BF",
	"continent": "Africa",
	"calling_code": 226,
	"tld": ".bf",
	"landlocked": "1",
	"population": 19751535,
	"area": 274000
}, {
	"name": "Burundi",
	"capital": "Bujumbura",
	"lat": "-3.366666667",
	"lng": "29.35",
	"code": "BI",
	"continent": "Africa",
	"calling_code": 257,
	"tld": ".bi",
	"landlocked": "1",
	"population": 11175378,
	"area": 27834
}, {
	"name": "Cambodia",
	"capital": "Phnom Penh",
	"lat": "11.55",
	"lng": "104.916667",
	"code": "KH",
	"continent": "Asia",
	"calling_code": 855,
	"tld": ".kh",
	"landlocked": "0",
	"population": 16249798,
	"area": 181035
}, {
	"name": "Cameroon",
	"capital": "Yaounde",
	"lat": "3.866666667",
	"lng": "11.516667",
	"code": "CM",
	"continent": "Africa",
	"calling_code": 237,
	"tld": ".cm",
	"landlocked": "0",
	"population": 25216237,
	"area": 475442
}, {
	"name": "Canada",
	"capital": "Ottawa",
	"lat": "45.41666667",
	"lng": "-75.7",
	"code": "CA",
	"continent": "Central America",
	"calling_code": 1,
	"tld": ".ca",
	"landlocked": "0",
	"population": 37057765,
	"area": 9970610
}, {
	"name": "Cape Verde",
	"capital": "Praia",
	"lat": "14.91666667",
	"lng": "-23.516667",
	"code": "CV",
	"continent": "Africa",
	"calling_code": null,
	"tld": ".cv",
	"landlocked": "0",
	"population": 543767,
	"area": 4033
}, {
	"name": "Cayman Islands",
	"capital": "George Town",
	"lat": "19.3",
	"lng": "-81.383333",
	"code": "KY",
	"continent": "North America",
	"calling_code": 1345,
	"tld": ".ky",
	"landlocked": "0",
	"population": 64174,
	"area": 264
}, {
	"name": "Central African Republic",
	"capital": "Bangui",
	"lat": "4.366666667",
	"lng": "18.583333",
	"code": "CF",
	"continent": "Africa",
	"calling_code": 236,
	"tld": ".cf",
	"landlocked": "1",
	"population": 4666377,
	"area": 622984
}, {
	"name": "Chad",
	"capital": "N’Djamena",
	"lat": "12.1",
	"lng": "15.033333",
	"code": "TD",
	"continent": "Africa",
	"calling_code": 235,
	"tld": ".td",
	"landlocked": "1",
	"population": 15477751,
	"area": 1284000
}, {
	"name": "Chile",
	"capital": "Santiago",
	"lat": "-33.45",
	"lng": "-70.666667",
	"code": "CL",
	"continent": "South America",
	"calling_code": 56,
	"tld": ".cl",
	"landlocked": "0",
	"population": 18729160,
	"area": 756626
}, {
	"name": "China",
	"capital": "Beijing",
	"lat": "39.91666667",
	"lng": "116.383333",
	"code": "CN",
	"continent": "Asia",
	"calling_code": 86,
	"tld": ".cn",
	"landlocked": "0",
	"population": 1392730000,
	"area": 9572900
}, {
	"name": "Christmas Island",
	"capital": "The Settlement",
	"lat": "-10.41666667",
	"lng": "105.716667",
	"code": "CX",
	"continent": "Australia",
	"calling_code": 61,
	"tld": ".cx",
	"landlocked": "0",
	"population": 1402,
	"area": 135
}, {
	"name": "Cocos Islands",
	"capital": "West Island",
	"lat": "-12.16666667",
	"lng": "96.833333",
	"code": "CC",
	"continent": "Australia"
}, {
	"name": "Colombia",
	"capital": "Bogota",
	"lat": "4.6",
	"lng": "-74.083333",
	"code": "CO",
	"continent": "South America",
	"calling_code": 57,
	"tld": ".co",
	"landlocked": "0",
	"population": 49648685,
	"area": 1138914
}, {
	"name": "Comoros",
	"capital": "Moroni",
	"lat": "-11.7",
	"lng": "43.233333",
	"code": "KM",
	"continent": "Africa",
	"calling_code": 269,
	"tld": ".km",
	"landlocked": "0",
	"population": 832322,
	"area": 1862
}, {
	"name": "Cook Islands",
	"capital": "Avarua",
	"lat": "-21.2",
	"lng": "-159.766667",
	"code": "CK",
	"continent": "Australia",
	"calling_code": 682,
	"tld": ".ck",
	"landlocked": "0",
	"population": 17379,
	"area": 236
}, {
	"name": "Costa Rica",
	"capital": "San Jose",
	"lat": "9.933333333",
	"lng": "-84.083333",
	"code": "CR",
	"continent": "Central America",
	"calling_code": 506,
	"tld": ".cr",
	"landlocked": "0",
	"population": 4999441,
	"area": 51100
}, {
	"name": "Cote d’Ivoire",
	"capital": "Yamoussoukro",
	"lat": "6.816666667",
	"lng": "-5.266667",
	"code": "CI",
	"continent": "Africa"
}, {
	"name": "Croatia",
	"capital": "Zagreb",
	"lat": "45.8",
	"lng": "16",
	"code": "HR",
	"continent": "Europe",
	"calling_code": 385,
	"tld": ".hr",
	"landlocked": "0",
	"population": 4087843,
	"area": 56538
}, {
	"name": "Cuba",
	"capital": "Havana",
	"lat": "23.11666667",
	"lng": "-82.35",
	"code": "CU",
	"continent": "North America",
	"calling_code": 53,
	"tld": ".cu",
	"landlocked": "0",
	"population": 11338138,
	"area": 110861
}, {
	"name": "CuraÃ§ao",
	"capital": "Willemstad",
	"lat": "12.1",
	"lng": "-68.916667",
	"code": "CW",
	"continent": "North America"
}, {
	"name": "Cyprus",
	"capital": "Nicosia",
	"lat": "35.16666667",
	"lng": "33.366667",
	"code": "CY",
	"continent": "Europe",
	"calling_code": 357,
	"tld": ".cy",
	"landlocked": "0",
	"population": 1189265,
	"area": 9251
}, {
	"name": "Czech Republic",
	"capital": "Prague",
	"lat": "50.08333333",
	"lng": "14.466667",
	"code": "CZ",
	"continent": "Europe",
	"calling_code": 420,
	"tld": ".cz",
	"landlocked": "1",
	"population": 10629928,
	"area": 78866
}, {
	"name": "Democratic Republic of the Congo",
	"capital": "Kinshasa",
	"lat": "-4.316666667",
	"lng": "15.3",
	"code": "CD",
	"continent": "Africa"
}, {
	"name": "Denmark",
	"capital": "Copenhagen",
	"lat": "55.66666667",
	"lng": "12.583333",
	"code": "DK",
	"continent": "Europe",
	"calling_code": 45,
	"tld": ".dk",
	"landlocked": "0",
	"population": 5793636,
	"area": 43094
}, {
	"name": "Djibouti",
	"capital": "Djibouti",
	"lat": "11.58333333",
	"lng": "43.15",
	"code": "DJ",
	"continent": "Africa",
	"calling_code": 253,
	"tld": ".dj",
	"landlocked": "0",
	"population": 958920,
	"area": 23200
}, {
	"name": "Dominica",
	"capital": "Roseau",
	"lat": "15.3",
	"lng": "-61.4",
	"code": "DM",
	"continent": "North America",
	"calling_code": 1767,
	"tld": ".dm",
	"landlocked": "0",
	"population": 71625,
	"area": 751
}, {
	"name": "Dominican Republic",
	"capital": "Santo Domingo",
	"lat": "18.46666667",
	"lng": "-69.9",
	"code": "DO",
	"continent": "North America",
	"calling_code": 1849,
	"tld": ".do",
	"landlocked": "0",
	"population": 10627165,
	"area": 48511
}, {
	"name": "Ecuador",
	"capital": "Quito",
	"lat": "-0.216666667",
	"lng": "-78.5",
	"code": "EC",
	"continent": "South America",
	"calling_code": 593,
	"tld": ".ec",
	"landlocked": "0",
	"population": 17084357,
	"area": 283561
}, {
	"name": "Egypt",
	"capital": "Cairo",
	"lat": "30.05",
	"lng": "31.25",
	"code": "EG",
	"continent": "Africa",
	"calling_code": 20,
	"tld": ".eg",
	"landlocked": "0",
	"population": 98423595,
	"area": 1001449
}, {
	"name": "El Salvador",
	"capital": "San Salvador",
	"lat": "13.7",
	"lng": "-89.2",
	"code": "SV",
	"continent": "Central America",
	"calling_code": 503,
	"tld": ".sv",
	"landlocked": "0",
	"population": 6420744,
	"area": 21041
}, {
	"name": "Equatorial Guinea",
	"capital": "Malabo",
	"lat": "3.75",
	"lng": "8.783333",
	"code": "GQ",
	"continent": "Africa",
	"calling_code": 240,
	"tld": ".gq",
	"landlocked": "0",
	"population": 1308974,
	"area": 28051
}, {
	"name": "Eritrea",
	"capital": "Asmara",
	"lat": "15.33333333",
	"lng": "38.933333",
	"code": "ER",
	"continent": "Africa",
	"calling_code": 291,
	"tld": ".er",
	"landlocked": "0",
	"population": 3213972,
	"area": 117600
}, {
	"name": "Estonia",
	"capital": "Tallinn",
	"lat": "59.43333333",
	"lng": "24.716667",
	"code": "EE",
	"continent": "Europe",
	"calling_code": 372,
	"tld": ".ee",
	"landlocked": "0",
	"population": 1321977,
	"area": 45227
}, {
	"name": "Ethiopia",
	"capital": "Addis Ababa",
	"lat": "9.033333333",
	"lng": "38.7",
	"code": "ET",
	"continent": "Africa",
	"calling_code": 251,
	"tld": ".et",
	"landlocked": "1",
	"population": 109224559,
	"area": 1104300
}, {
	"name": "Falkland Islands",
	"capital": "Stanley",
	"lat": "-51.7",
	"lng": "-57.85",
	"code": "FK",
	"continent": "South America",
	"calling_code": 500,
	"tld": ".fk",
	"landlocked": "0",
	"population": 2840,
	"area": 12173
}, {
	"name": "Faroe Islands",
	"capital": "Torshavn",
	"lat": "62",
	"lng": "-6.766667",
	"code": "FO",
	"continent": "Europe",
	"calling_code": 298,
	"tld": null,
	"landlocked": "0",
	"population": 48497,
	"area": 1399
}, {
	"name": "Federated States of Micronesia",
	"capital": "Palikir",
	"lat": "6.916666667",
	"lng": "158.15",
	"code": "FM",
	"continent": "Australia",
	"area": 702
}, {
	"name": "Fiji",
	"capital": "Suva",
	"lat": "-18.13333333",
	"lng": "178.416667",
	"code": "FJ",
	"continent": "Australia"
}, {
	"name": "Finland",
	"capital": "Helsinki",
	"lat": "60.16666667",
	"lng": "24.933333",
	"code": "FI",
	"continent": "Europe",
	"calling_code": 358,
	"tld": ".fi",
	"landlocked": "0",
	"population": 5515525,
	"area": 338145
}, {
	"name": "France",
	"capital": "Paris",
	"lat": "48.86666667",
	"lng": "2.333333",
	"code": "FR",
	"continent": "Europe",
	"calling_code": 33,
	"tld": ".fr",
	"landlocked": "0",
	"population": 66977107,
	"area": 551500
}, {
	"name": "French Polynesia",
	"capital": "Papeete",
	"lat": "-17.53333333",
	"lng": "-149.566667",
	"code": "PF",
	"continent": "Australia",
	"calling_code": 689,
	"tld": ".pf",
	"landlocked": "0",
	"population": 277679,
	"area": 4000
}, {
	"name": "French Southern and Antarctic Lands",
	"capital": "Port-aux-FranÃ§ais",
	"lat": "-49.35",
	"lng": "70.216667",
	"code": "TF",
	"continent": "Antarctica"
}, {
	"name": "Gabon",
	"capital": "Libreville",
	"lat": "0.383333333",
	"lng": "9.45",
	"code": "GA",
	"continent": "Africa",
	"calling_code": 241,
	"tld": ".ga",
	"landlocked": "0",
	"population": 2119275,
	"area": 267668
}, {
	"name": "Georgia",
	"capital": "Tbilisi",
	"lat": "41.68333333",
	"lng": "44.833333",
	"code": "GE",
	"continent": "Europe",
	"calling_code": 995,
	"tld": ".ge",
	"landlocked": "0",
	"population": 3726549,
	"area": 69700
}, {
	"name": "Germany",
	"capital": "Berlin",
	"lat": "52.51666667",
	"lng": "13.4",
	"code": "DE",
	"continent": "Europe",
	"calling_code": 49,
	"tld": ".de",
	"landlocked": "0",
	"population": 82905782,
	"area": 357022
}, {
	"name": "Ghana",
	"capital": "Accra",
	"lat": "5.55",
	"lng": "-0.216667",
	"code": "GH",
	"continent": "Africa",
	"calling_code": 233,
	"tld": ".gh",
	"landlocked": "0",
	"population": 29767108,
	"area": 238533
}, {
	"name": "Gibraltar",
	"capital": "Gibraltar",
	"lat": "36.13333333",
	"lng": "-5.35",
	"code": "GI",
	"continent": "Europe",
	"calling_code": 350,
	"tld": ".gi",
	"landlocked": "0",
	"population": 33718,
	"area": 6
}, {
	"name": "Greece",
	"capital": "Athens",
	"lat": "37.98333333",
	"lng": "23.733333",
	"code": "GR",
	"continent": "Europe",
	"calling_code": 30,
	"tld": ".gr",
	"landlocked": "0",
	"population": 10731726,
	"area": 131626
}, {
	"name": "Greenland",
	"capital": "Nuuk",
	"lat": "64.18333333",
	"lng": "-51.75",
	"code": "GL",
	"continent": "Central America",
	"calling_code": 299,
	"tld": ".gl",
	"landlocked": "0",
	"population": 56025,
	"area": 2166090
}, {
	"name": "Grenada",
	"capital": "Saint George’s",
	"lat": "12.05",
	"lng": "-61.75",
	"code": "GD",
	"continent": "North America",
	"calling_code": 1473,
	"tld": ".gd",
	"landlocked": "0",
	"population": 111454,
	"area": 344
}, {
	"name": "Guam",
	"capital": "Hagatna",
	"lat": "13.46666667",
	"lng": "144.733333",
	"code": "GU",
	"continent": "Australia",
	"calling_code": 1671,
	"tld": ".gu",
	"landlocked": "0",
	"population": 165768,
	"area": 549
}, {
	"name": "Guatemala",
	"capital": "Guatemala City",
	"lat": "14.61666667",
	"lng": "-90.516667",
	"code": "GT",
	"continent": "Central America",
	"calling_code": 502,
	"tld": ".gt",
	"landlocked": "0",
	"population": 17247807,
	"area": 108889
}, {
	"name": "Guernsey",
	"capital": "Saint Peter Port",
	"lat": "49.45",
	"lng": "-2.533333",
	"code": "GG",
	"continent": "Europe"
}, {
	"name": "Guinea",
	"capital": "Conakry",
	"lat": "9.5",
	"lng": "-13.7",
	"code": "GN",
	"continent": "Africa",
	"calling_code": 224,
	"tld": ".gn",
	"landlocked": "0",
	"population": 12414318,
	"area": 245857
}, {
	"name": "Guinea-Bissau",
	"capital": "Bissau",
	"lat": "11.85",
	"lng": "-15.583333",
	"code": "GW",
	"continent": "Africa",
	"calling_code": 245,
	"tld": ".gw",
	"landlocked": "0",
	"population": 1874309,
	"area": 36125
}, {
	"name": "Guyana",
	"capital": "Georgetown",
	"lat": "6.8",
	"lng": "-58.15",
	"code": "GY",
	"continent": "South America",
	"calling_code": 592,
	"tld": ".gy",
	"landlocked": "0",
	"population": 779004,
	"area": 214969
}, {
	"name": "Haiti",
	"capital": "Port-au-Prince",
	"lat": "18.53333333",
	"lng": "-72.333333",
	"code": "HT",
	"continent": "North America",
	"calling_code": 509,
	"tld": ".ht",
	"landlocked": "0",
	"population": 11123176,
	"area": 27750
}, {
	"name": "Honduras",
	"capital": "Tegucigalpa",
	"lat": "14.1",
	"lng": "-87.216667",
	"code": "HN",
	"continent": "Central America",
	"calling_code": 504,
	"tld": ".hn",
	"landlocked": "0",
	"population": 9587522,
	"area": 112088
}, {
	"name": "Hungary",
	"capital": "Budapest",
	"lat": "47.5",
	"lng": "19.083333",
	"code": "HU",
	"continent": "Europe",
	"calling_code": 36,
	"tld": ".hu",
	"landlocked": "1",
	"population": 9775564,
	"area": 93030
}, {
	"name": "Iceland",
	"capital": "Reykjavik",
	"lat": "64.15",
	"lng": "-21.95",
	"code": "IS",
	"continent": "Europe",
	"calling_code": 354,
	"tld": ".is",
	"landlocked": "0",
	"population": 352721,
	"area": 103000
}, {
	"name": "India",
	"capital": "New Delhi",
	"lat": "28.6",
	"lng": "77.2",
	"code": "IN",
	"continent": "Asia",
	"calling_code": 91,
	"tld": ".in",
	"landlocked": "0",
	"population": 1352617328,
	"area": 3287263
}, {
	"name": "Indonesia",
	"capital": "Jakarta",
	"lat": "-6.166666667",
	"lng": "106.816667",
	"code": "ID",
	"continent": "Asia",
	"calling_code": 62,
	"tld": ".id",
	"landlocked": "0",
	"population": 267663435,
	"area": 1904569
}, {
	"name": "Iran",
	"capital": "Tehran",
	"lat": "35.7",
	"lng": "51.416667",
	"code": "IR",
	"continent": "Asia",
	"calling_code": 98,
	"tld": ".ir",
	"landlocked": "0",
	"population": 81800269,
	"area": 1648195
}, {
	"name": "Iraq",
	"capital": "Baghdad",
	"lat": "33.33333333",
	"lng": "44.4",
	"code": "IQ",
	"continent": "Asia",
	"calling_code": 964,
	"tld": ".iq",
	"landlocked": "0",
	"population": 38433600,
	"area": 438317
}, {
	"name": "Ireland",
	"capital": "Dublin",
	"lat": "53.31666667",
	"lng": "-6.233333",
	"code": "IE",
	"continent": "Europe",
	"calling_code": 353,
	"tld": ".ie",
	"landlocked": "0",
	"population": 4867309,
	"area": 70273
}, {
	"name": "Isle of Man",
	"capital": "Douglas",
	"lat": "54.15",
	"lng": "-4.483333",
	"code": "IM",
	"continent": "Europe"
}, {
	"name": "Israel",
	"capital": "Jerusalem",
	"lat": "31.76666667",
	"lng": "35.233333",
	"code": "IL",
	"continent": "Asia",
	"calling_code": 972,
	"tld": ".il",
	"landlocked": "0",
	"population": 8882800,
	"area": 21056
}, {
	"name": "Italy",
	"capital": "Rome",
	"lat": "41.9",
	"lng": "12.483333",
	"code": "IT",
	"continent": "Europe",
	"calling_code": 39,
	"tld": ".it",
	"landlocked": "0",
	"population": 60421760,
	"area": 301316
}, {
	"name": "Jamaica",
	"capital": "Kingston",
	"lat": "18",
	"lng": "-76.8",
	"code": "JM",
	"continent": "North America",
	"calling_code": 1876,
	"tld": ".jm",
	"landlocked": "0",
	"population": 2934855,
	"area": 10990
}, {
	"name": "Japan",
	"capital": "Tokyo",
	"lat": "35.68333333",
	"lng": "139.75",
	"code": "JP",
	"continent": "Asia",
	"calling_code": 81,
	"tld": ".jp",
	"landlocked": "0",
	"population": 126529100,
	"area": 377829
}, {
	"name": "Jersey",
	"capital": "Saint Helier",
	"lat": "49.18333333",
	"lng": "-2.1",
	"code": "JE",
	"continent": "Europe"
}, {
	"name": "Jordan",
	"capital": "Amman",
	"lat": "31.95",
	"lng": "35.933333",
	"code": "JO",
	"continent": "Asia",
	"calling_code": 962,
	"tld": ".jo",
	"landlocked": "0",
	"population": 9956011,
	"area": 88946
}, {
	"name": "Kazakhstan",
	"capital": "Astana",
	"lat": "51.16666667",
	"lng": "71.416667",
	"code": "KZ",
	"continent": "Asia",
	"calling_code": 7,
	"tld": ".kz",
	"landlocked": "1",
	"population": 18272430,
	"area": 2724900
}, {
	"name": "Kenya",
	"capital": "Nairobi",
	"lat": "-1.283333333",
	"lng": "36.816667",
	"code": "KE",
	"continent": "Africa",
	"calling_code": 254,
	"tld": ".ke",
	"landlocked": "0",
	"population": 51393010,
	"area": 580367
}, {
	"name": "Kiribati",
	"capital": "Tarawa",
	"lat": "-0.883333333",
	"lng": "169.533333",
	"code": "KI",
	"continent": "Australia",
	"calling_code": 686,
	"tld": ".ki",
	"landlocked": "0",
	"population": 115847,
	"area": 726
}, {
	"name": "Kuwait",
	"capital": "Kuwait City",
	"lat": "29.36666667",
	"lng": "47.966667",
	"code": "KW",
	"continent": "Asia",
	"calling_code": 965,
	"tld": ".kw",
	"landlocked": "0",
	"population": 4137309,
	"area": 17818
}, {
	"name": "Kyrgyzstan",
	"capital": "Bishkek",
	"lat": "42.86666667",
	"lng": "74.6",
	"code": "KG",
	"continent": "Asia",
	"calling_code": 996,
	"tld": ".kg",
	"landlocked": "1",
	"population": 6322800,
	"area": 199900
}, {
	"name": "Laos",
	"capital": "Vientiane",
	"lat": "17.96666667",
	"lng": "102.6",
	"code": "LA",
	"continent": "Asia",
	"calling_code": 856,
	"tld": ".la",
	"landlocked": "1",
	"population": 7061507,
	"area": 236800
}, {
	"name": "Latvia",
	"capital": "Riga",
	"lat": "56.95",
	"lng": "24.1",
	"code": "LV",
	"continent": "Europe",
	"calling_code": 371,
	"tld": ".lv",
	"landlocked": "0",
	"population": 1927174,
	"area": 64589
}, {
	"name": "Lebanon",
	"capital": "Beirut",
	"lat": "33.86666667",
	"lng": "35.5",
	"code": "LB",
	"continent": "Asia",
	"calling_code": 961,
	"tld": ".lb",
	"landlocked": "0",
	"population": 6848925,
	"area": 10400
}, {
	"name": "Lesotho",
	"capital": "Maseru",
	"lat": "-29.31666667",
	"lng": "27.483333",
	"code": "LS",
	"continent": "Africa",
	"calling_code": 266,
	"tld": ".ls",
	"landlocked": "1",
	"population": 2108132,
	"area": 30355
}, {
	"name": "Liberia",
	"capital": "Monrovia",
	"lat": "6.3",
	"lng": "-10.8",
	"code": "LR",
	"continent": "Africa",
	"calling_code": 231,
	"tld": ".lr",
	"landlocked": "0",
	"population": 4818977,
	"area": 111369
}, {
	"name": "Libya",
	"capital": "Tripoli",
	"lat": "32.88333333",
	"lng": "13.166667",
	"code": "LY",
	"continent": "Africa"
}, {
	"name": "Liechtenstein",
	"capital": "Vaduz",
	"lat": "47.13333333",
	"lng": "9.516667",
	"code": "LI",
	"continent": "Europe",
	"calling_code": 423,
	"tld": ".li",
	"landlocked": "1",
	"population": 37910,
	"area": 160
}, {
	"name": "Lithuania",
	"capital": "Vilnius",
	"lat": "54.68333333",
	"lng": "25.316667",
	"code": "LT",
	"continent": "Europe",
	"calling_code": 370,
	"tld": ".lt",
	"landlocked": "0",
	"population": 2801543,
	"area": 65301
}, {
	"name": "Luxembourg",
	"capital": "Luxembourg",
	"lat": "49.6",
	"lng": "6.116667",
	"code": "LU",
	"continent": "Europe",
	"calling_code": 352,
	"tld": ".lu",
	"landlocked": "1",
	"population": 607950,
	"area": 2586
}, {
	"name": "Macedonia",
	"capital": "Skopje",
	"lat": "42",
	"lng": "21.433333",
	"code": "MK",
	"continent": "Europe"
}, {
	"name": "Madagascar",
	"capital": "Antananarivo",
	"lat": "-18.91666667",
	"lng": "47.516667",
	"code": "MG",
	"continent": "Africa",
	"calling_code": 261,
	"tld": ".mg",
	"landlocked": "0",
	"population": 26262368,
	"area": 587041
}, {
	"name": "Malawi",
	"capital": "Lilongwe",
	"lat": "-13.96666667",
	"lng": "33.783333",
	"code": "MW",
	"continent": "Africa",
	"calling_code": 265,
	"tld": ".mw",
	"landlocked": "1",
	"population": 18143315,
	"area": 118484
}, {
	"name": "Malaysia",
	"capital": "Kuala Lumpur",
	"lat": "3.166666667",
	"lng": "101.7",
	"code": "MY",
	"continent": "Asia",
	"calling_code": 60,
	"tld": ".my",
	"landlocked": "0",
	"population": 31528585,
	"area": 329758
}, {
	"name": "Maldives",
	"capital": "Male",
	"lat": "4.166666667",
	"lng": "73.5",
	"code": "MV",
	"continent": "Asia",
	"calling_code": 960,
	"tld": ".mv",
	"landlocked": "0",
	"population": 515696,
	"area": 298
}, {
	"name": "Mali",
	"capital": "Bamako",
	"lat": "12.65",
	"lng": "-8",
	"code": "ML",
	"continent": "Africa",
	"calling_code": 223,
	"tld": ".ml",
	"landlocked": "1",
	"population": 19077690,
	"area": 1240192
}, {
	"name": "Malta",
	"capital": "Valletta",
	"lat": "35.88333333",
	"lng": "14.5",
	"code": "MT",
	"continent": "Europe",
	"calling_code": 356,
	"tld": ".mt",
	"landlocked": "0",
	"population": 484630,
	"area": 316
}, {
	"name": "Marshall Islands",
	"capital": "Majuro",
	"lat": "7.1",
	"lng": "171.383333",
	"code": "MH",
	"continent": "Australia",
	"calling_code": 692,
	"tld": ".mh",
	"landlocked": "0",
	"population": 58413,
	"area": 181
}, {
	"name": "Mauritania",
	"capital": "Nouakchott",
	"lat": "18.06666667",
	"lng": "-15.966667",
	"code": "MR",
	"continent": "Africa",
	"calling_code": 222,
	"tld": ".mr",
	"landlocked": "0",
	"population": 4403319,
	"area": 1025520
}, {
	"name": "Mauritius",
	"capital": "Port Louis",
	"lat": "-20.15",
	"lng": "57.483333",
	"code": "MU",
	"continent": "Africa",
	"calling_code": 230,
	"tld": ".mu",
	"landlocked": "0",
	"population": 1265303,
	"area": 2040
}, {
	"name": "Mexico",
	"capital": "Mexico City",
	"lat": "19.43333333",
	"lng": "-99.133333",
	"code": "MX",
	"continent": "Central America",
	"calling_code": 52,
	"tld": ".mx",
	"landlocked": "0",
	"population": 126190788,
	"area": 1958201
}, {
	"name": "Moldova",
	"capital": "Chisinau",
	"lat": "47",
	"lng": "28.85",
	"code": "MD",
	"continent": "Europe",
	"calling_code": 373,
	"tld": ".md",
	"landlocked": "1",
	"population": 2706049,
	"area": 33851
}, {
	"name": "Monaco",
	"capital": "Monaco",
	"lat": "43.73333333",
	"lng": "7.416667",
	"code": "MC",
	"continent": "Europe",
	"calling_code": 377,
	"tld": ".mc",
	"landlocked": "0",
	"population": 38682,
	"area": 1.5
}, {
	"name": "Mongolia",
	"capital": "Ulaanbaatar",
	"lat": "47.91666667",
	"lng": "106.916667",
	"code": "MN",
	"continent": "Asia",
	"calling_code": 976,
	"tld": ".mn",
	"landlocked": "1",
	"population": 3170208,
	"area": 1566500
}, {
	"name": "Montenegro",
	"capital": "Podgorica",
	"lat": "42.43333333",
	"lng": "19.266667",
	"code": "ME",
	"continent": "Europe",
	"population": 631219
}, {
	"name": "Montserrat",
	"capital": "Plymouth",
	"lat": "16.7",
	"lng": "-62.216667",
	"code": "MS",
	"continent": "North America",
	"calling_code": 1664,
	"tld": ".ms",
	"landlocked": "0",
	"population": 5900,
	"area": 102
}, {
	"name": "Morocco",
	"capital": "Rabat",
	"lat": "34.01666667",
	"lng": "-6.816667",
	"code": "MA",
	"continent": "Africa",
	"calling_code": 212,
	"tld": ".ma",
	"landlocked": "0",
	"population": 36029138,
	"area": 446550
}, {
	"name": "Mozambique",
	"capital": "Maputo",
	"lat": "-25.95",
	"lng": "32.583333",
	"code": "MZ",
	"continent": "Africa",
	"calling_code": 258,
	"tld": ".mz",
	"landlocked": "0",
	"population": 29495962,
	"area": 801590
}, {
	"name": "Myanmar",
	"capital": "Rangoon",
	"lat": "16.8",
	"lng": "96.15",
	"code": "MM",
	"continent": "Asia",
	"calling_code": 95,
	"tld": ".mm",
	"landlocked": "0",
	"population": 53708395,
	"area": 676578
}, {
	"name": "Namibia",
	"capital": "Windhoek",
	"lat": "-22.56666667",
	"lng": "17.083333",
	"code": "NA",
	"continent": "Africa",
	"calling_code": 264,
	"tld": ".na",
	"landlocked": "0",
	"population": 2448255,
	"area": 824292
}, {
	"name": "Nauru",
	"capital": "Yaren",
	"lat": "-0.5477",
	"lng": "166.920867",
	"code": "NR",
	"continent": "Australia",
	"calling_code": 674,
	"tld": ".nr",
	"landlocked": "0",
	"population": 12704,
	"area": 21
}, {
	"name": "Nepal",
	"capital": "Kathmandu",
	"lat": "27.71666667",
	"lng": "85.316667",
	"code": "NP",
	"continent": "Asia",
	"calling_code": 977,
	"tld": ".np",
	"landlocked": "1",
	"population": 28087871,
	"area": 147181
}, {
	"name": "Netherlands",
	"capital": "Amsterdam",
	"lat": "52.35",
	"lng": "4.916667",
	"code": "NL",
	"continent": "Europe",
	"calling_code": 31,
	"tld": ".nl",
	"landlocked": "0",
	"population": 17231624,
	"area": 41526
}, {
	"name": "New Caledonia",
	"capital": "Noumea",
	"lat": "-22.26666667",
	"lng": "166.45",
	"code": "NC",
	"continent": "Australia",
	"calling_code": 687,
	"tld": ".nc",
	"landlocked": "0",
	"population": 284060,
	"area": 18575
}, {
	"name": "New Zealand",
	"capital": "Wellington",
	"lat": "-41.3",
	"lng": "174.783333",
	"code": "NZ",
	"continent": "Australia",
	"calling_code": 64,
	"tld": ".nz",
	"landlocked": "0",
	"population": 4841000,
	"area": 270534
}, {
	"name": "Nicaragua",
	"capital": "Managua",
	"lat": "12.13333333",
	"lng": "-86.25",
	"code": "NI",
	"continent": "Central America",
	"calling_code": 505,
	"tld": ".ni",
	"landlocked": "0",
	"population": 6465513,
	"area": 130000
}, {
	"name": "Niger",
	"capital": "Niamey",
	"lat": "13.51666667",
	"lng": "2.116667",
	"code": "NE",
	"continent": "Africa",
	"calling_code": 227,
	"tld": ".ne",
	"landlocked": "1",
	"population": 22442948,
	"area": 1267000
}, {
	"name": "Nigeria",
	"capital": "Abuja",
	"lat": "9.083333333",
	"lng": "7.533333",
	"code": "NG",
	"continent": "Africa",
	"calling_code": 234,
	"tld": ".ng",
	"landlocked": "0",
	"population": 195874740,
	"area": 923768
}, {
	"name": "Niue",
	"capital": "Alofi",
	"lat": "-19.01666667",
	"lng": "-169.916667",
	"code": "NU",
	"continent": "Australia",
	"calling_code": 683,
	"tld": ".nu",
	"landlocked": "0",
	"population": 1624,
	"area": 260
}, {
	"name": "Norfolk Island",
	"capital": "Kingston",
	"lat": "-29.05",
	"lng": "167.966667",
	"code": "NF",
	"continent": "Australia",
	"calling_code": 672,
	"tld": ".nf",
	"landlocked": "0",
	"population": 2169,
	"area": 36
}, {
	"name": "North Korea",
	"capital": "Pyongyang",
	"lat": "39.01666667",
	"lng": "125.75",
	"code": "KP",
	"continent": "Asia",
	"calling_code": null,
	"tld": ".kp",
	"landlocked": "0",
	"population": 25549819,
	"area": 120538
}, {
	"name": "Northern Mariana Islands",
	"capital": "Saipan",
	"lat": "15.2",
	"lng": "145.75",
	"code": "MP",
	"continent": "Australia",
	"calling_code": 1670,
	"tld": ".mp",
	"landlocked": "0",
	"population": 56882,
	"area": 464
}, {
	"name": "Norway",
	"capital": "Oslo",
	"lat": "59.91666667",
	"lng": "10.75",
	"code": "NO",
	"continent": "Europe",
	"calling_code": 47,
	"tld": ".no",
	"landlocked": "0",
	"population": 5311916,
	"area": 323877
}, {
	"name": "Oman",
	"capital": "Muscat",
	"lat": "23.61666667",
	"lng": "58.583333",
	"code": "OM",
	"continent": "Asia",
	"calling_code": 968,
	"tld": ".om",
	"landlocked": "0",
	"population": 4829483,
	"area": 309500
}, {
	"name": "Pakistan",
	"capital": "Islamabad",
	"lat": "33.68333333",
	"lng": "73.05",
	"code": "PK",
	"continent": "Asia",
	"calling_code": 92,
	"tld": ".pk",
	"landlocked": "0",
	"population": 212215030,
	"area": 796095
}, {
	"name": "Palau",
	"capital": "Melekeok",
	"lat": "7.483333333",
	"lng": "134.633333",
	"code": "PW",
	"continent": "Australia",
	"calling_code": 680,
	"tld": ".pw",
	"landlocked": "0",
	"population": 17907,
	"area": 459
}, {
	"name": "Palestine",
	"capital": "Jerusalem",
	"lat": "31.76666667",
	"lng": "35.233333",
	"code": "PS",
	"continent": "Asia",
	"calling_code": null,
	"tld": ".ps",
	"landlocked": "0",
	"population": 4569087,
	"area": 6257
}, {
	"name": "Panama",
	"capital": "Panama City",
	"lat": "8.966666667",
	"lng": "-79.533333",
	"code": "PA",
	"continent": "Central America",
	"calling_code": 507,
	"tld": ".pa",
	"landlocked": "0",
	"population": 4176873,
	"area": 75517
}, {
	"name": "Papua New Guinea",
	"capital": "Port Moresby",
	"lat": "-9.45",
	"lng": "147.183333",
	"code": "PG",
	"continent": "Australia",
	"calling_code": 675,
	"tld": ".pg",
	"landlocked": "0",
	"population": 8606316,
	"area": 462840
}, {
	"name": "Paraguay",
	"capital": "Asuncion",
	"lat": "-25.26666667",
	"lng": "-57.666667",
	"code": "PY",
	"continent": "South America",
	"calling_code": 595,
	"tld": ".py",
	"landlocked": "1",
	"population": 6956071,
	"area": 406752
}, {
	"name": "Peru",
	"capital": "Lima",
	"lat": "-12.05",
	"lng": "-77.05",
	"code": "PE",
	"continent": "South America",
	"calling_code": 51,
	"tld": ".pe",
	"landlocked": "0",
	"population": 31989256,
	"area": 1285216
}, {
	"name": "Philippines",
	"capital": "Manila",
	"lat": "14.6",
	"lng": "120.966667",
	"code": "PH",
	"continent": "Asia",
	"calling_code": 63,
	"tld": ".ph",
	"landlocked": "0",
	"population": 106651922,
	"area": 300000
}, {
	"name": "Pitcairn Islands",
	"capital": "Adamstown",
	"lat": "-25.06666667",
	"lng": "-130.083333",
	"code": "PN",
	"continent": "Australia"
}, {
	"name": "Poland",
	"capital": "Warsaw",
	"lat": "52.25",
	"lng": "21",
	"code": "PL",
	"continent": "Europe",
	"calling_code": 48,
	"tld": ".pl",
	"landlocked": "0",
	"population": 37974750,
	"area": 323250
}, {
	"name": "Portugal",
	"capital": "Lisbon",
	"lat": "38.71666667",
	"lng": "-9.133333",
	"code": "PT",
	"continent": "Europe",
	"calling_code": 351,
	"tld": ".pt",
	"landlocked": "0",
	"population": 10283822,
	"area": 91982
}, {
	"name": "Puerto Rico",
	"capital": "San Juan",
	"lat": "18.46666667",
	"lng": "-66.116667",
	"code": "PR",
	"continent": "North America",
	"calling_code": 1939,
	"tld": ".pr",
	"landlocked": "0",
	"population": 3195153,
	"area": 8875
}, {
	"name": "Qatar",
	"capital": "Doha",
	"lat": "25.28333333",
	"lng": "51.533333",
	"code": "QA",
	"continent": "Asia",
	"calling_code": 974,
	"tld": ".qa",
	"landlocked": "0",
	"population": 2781677,
	"area": 11000
}, {
	"name": "Republic of Congo",
	"capital": "Brazzaville",
	"lat": "-4.25",
	"lng": "15.283333",
	"code": "CG",
	"continent": "Africa"
}, {
	"name": "Romania",
	"capital": "Bucharest",
	"lat": "44.43333333",
	"lng": "26.1",
	"code": "RO",
	"continent": "Europe",
	"calling_code": 40,
	"tld": ".ro",
	"landlocked": "0",
	"population": 19466145,
	"area": 238391
}, {
	"name": "Russia",
	"capital": "Moscow",
	"lat": "55.75",
	"lng": "37.6",
	"code": "RU",
	"continent": "Europe"
}, {
	"name": "Rwanda",
	"capital": "Kigali",
	"lat": "-1.95",
	"lng": "30.05",
	"code": "RW",
	"continent": "Africa",
	"calling_code": 250,
	"tld": ".rw",
	"landlocked": "1",
	"population": 12301939,
	"area": 26338
}, {
	"name": "Saint Barthelemy",
	"capital": "Gustavia",
	"lat": "17.88333333",
	"lng": "-62.85",
	"code": "BL",
	"continent": "North America"
}, {
	"name": "Saint Helena",
	"capital": "Jamestown",
	"lat": "-15.93333333",
	"lng": "-5.716667",
	"code": "SH",
	"continent": "Africa",
	"calling_code": 290,
	"tld": ".sh",
	"landlocked": "0",
	"population": 6600,
	"area": 314
}, {
	"name": "Saint Kitts and Nevis",
	"capital": "Basseterre",
	"lat": "17.3",
	"lng": "-62.716667",
	"code": "KN",
	"continent": "North America",
	"calling_code": 1869,
	"tld": ".kn",
	"landlocked": "0",
	"population": 52441,
	"area": 261
}, {
	"name": "Saint Lucia",
	"capital": "Castries",
	"lat": "14",
	"lng": "-61",
	"code": "LC",
	"continent": "North America",
	"calling_code": 1758,
	"tld": ".lc",
	"landlocked": "0",
	"population": 181889,
	"area": 622
}, {
	"name": "Saint Martin",
	"capital": "Marigot",
	"lat": "18.0731",
	"lng": "-63.0822",
	"code": "MF",
	"continent": "North America"
}, {
	"name": "Saint Pierre and Miquelon",
	"capital": "Saint-Pierre",
	"lat": "46.76666667",
	"lng": "-56.183333",
	"code": "PM",
	"continent": "Central America",
	"calling_code": 508,
	"tld": ".pm",
	"landlocked": "0",
	"population": 5888,
	"area": 242
}, {
	"name": "Saint Vincent and the Grenadines",
	"capital": "Kingstown",
	"lat": "13.13333333",
	"lng": "-61.216667",
	"code": "VC",
	"continent": "Central America",
	"calling_code": 1784,
	"tld": ".vc",
	"landlocked": "0",
	"population": 110210,
	"area": 388
}, {
	"name": "Samoa",
	"capital": "Apia",
	"lat": "-13.81666667",
	"lng": "-171.766667",
	"code": "WS",
	"continent": "Australia",
	"calling_code": 685,
	"tld": ".ws",
	"landlocked": "0",
	"population": 196130,
	"area": 2831
}, {
	"name": "San Marino",
	"capital": "San Marino",
	"lat": "43.93333333",
	"lng": "12.416667",
	"code": "SM",
	"continent": "Europe",
	"calling_code": 378,
	"tld": ".sm",
	"landlocked": "1",
	"population": 33785,
	"area": 61
}, {
	"name": "Sao Tome and Principe",
	"capital": "Sao Tome",
	"lat": "0.333333333",
	"lng": "6.733333",
	"code": "ST",
	"continent": "Africa",
	"calling_code": 239,
	"tld": ".st",
	"landlocked": "0",
	"population": 211028,
	"area": 964
}, {
	"name": "Saudi Arabia",
	"capital": "Riyadh",
	"lat": "24.65",
	"lng": "46.7",
	"code": "SA",
	"continent": "Asia",
	"calling_code": 966,
	"tld": ".sa",
	"landlocked": "0",
	"population": 33699947,
	"area": 2149690
}, {
	"name": "Senegal",
	"capital": "Dakar",
	"lat": "14.73333333",
	"lng": "-17.633333",
	"code": "SN",
	"continent": "Africa",
	"calling_code": 221,
	"tld": ".sn",
	"landlocked": "0",
	"population": 15854360,
	"area": 196722
}, {
	"name": "Serbia",
	"capital": "Belgrade",
	"lat": "44.83333333",
	"lng": "20.5",
	"code": "RS",
	"continent": "Europe",
	"calling_code": 381,
	"tld": ".rs",
	"landlocked": "1",
	"population": 6963764,
	"area": 88361
}, {
	"name": "Seychelles",
	"capital": "Victoria",
	"lat": "-4.616666667",
	"lng": "55.45",
	"code": "SC",
	"continent": "Africa",
	"calling_code": 248,
	"tld": ".sc",
	"landlocked": "0",
	"population": 96762,
	"area": 455
}, {
	"name": "Sierra Leone",
	"capital": "Freetown",
	"lat": "8.483333333",
	"lng": "-13.233333",
	"code": "SL",
	"continent": "Africa",
	"calling_code": 232,
	"tld": ".sl",
	"landlocked": "0",
	"population": 7650154,
	"area": 71740
}, {
	"name": "Singapore",
	"capital": "Singapore",
	"lat": "1.283333333",
	"lng": "103.85",
	"code": "SG",
	"continent": "Asia",
	"calling_code": 65,
	"tld": ".sg",
	"landlocked": "0",
	"population": 5638676,
	"area": 618
}, {
	"name": "Sint Maarten",
	"capital": "Philipsburg",
	"lat": "18.01666667",
	"lng": "-63.033333",
	"code": "SX",
	"continent": "North America"
}, {
	"name": "Slovakia",
	"capital": "Bratislava",
	"lat": "48.15",
	"lng": "17.116667",
	"code": "SK",
	"continent": "Europe",
	"calling_code": 421,
	"tld": ".sk",
	"landlocked": "1",
	"population": 5446771,
	"area": 49012
}, {
	"name": "Slovenia",
	"capital": "Ljubljana",
	"lat": "46.05",
	"lng": "14.516667",
	"code": "SI",
	"continent": "Europe",
	"calling_code": 386,
	"tld": ".si",
	"landlocked": "0",
	"population": 2073894,
	"area": 20256
}, {
	"name": "Solomon Islands",
	"capital": "Honiara",
	"lat": "-9.433333333",
	"lng": "159.95",
	"code": "SB",
	"continent": "Australia",
	"calling_code": 677,
	"tld": ".sb",
	"landlocked": "0",
	"population": 652858,
	"area": 28896
}, {
	"name": "Somalia",
	"capital": "Mogadishu",
	"lat": "2.066666667",
	"lng": "45.333333",
	"code": "SO",
	"continent": "Africa",
	"calling_code": 252,
	"tld": ".so",
	"landlocked": "0",
	"population": 15008154,
	"area": 637657
}, {
	"name": "South Africa",
	"capital": "Pretoria",
	"lat": "-25.7",
	"lng": "28.216667",
	"code": "ZA",
	"continent": "Africa",
	"calling_code": 27,
	"tld": ".za",
	"landlocked": "0",
	"population": 57779622,
	"area": 1221037
}, {
	"name": "South Georgia and South Sandwich Islands",
	"capital": "King Edward Point",
	"lat": "-54.283333",
	"lng": "-36.5",
	"code": "GS",
	"continent": "Antarctica"
}, {
	"name": "South Korea",
	"capital": "Seoul",
	"lat": "37.55",
	"lng": "126.983333",
	"code": "KR",
	"continent": "Asia",
	"calling_code": null,
	"tld": ".kr",
	"landlocked": "0",
	"population": 51606633,
	"area": 99434
}, {
	"name": "South Sudan",
	"capital": "Juba",
	"lat": "4.85",
	"lng": "31.616667",
	"code": "SS",
	"continent": "Africa",
	"calling_code": 211,
	"tld": ".ss",
	"landlocked": "1",
	"population": 10975920,
	"area": 619745
}, {
	"name": "Spain",
	"capital": "Madrid",
	"lat": "40.4",
	"lng": "-3.683333",
	"code": "ES",
	"continent": "Europe",
	"calling_code": 34,
	"tld": ".es",
	"landlocked": "0",
	"population": 46796540,
	"area": 505992
}, {
	"name": "Sri Lanka",
	"capital": "Colombo",
	"lat": "6.916666667",
	"lng": "79.833333",
	"code": "LK",
	"continent": "Asia",
	"area": 65610
}, {
	"name": "Sudan",
	"capital": "Khartoum",
	"lat": "15.6",
	"lng": "32.533333",
	"code": "SD",
	"continent": "Africa",
	"calling_code": 249,
	"tld": ".sd",
	"landlocked": "0",
	"population": 41801533,
	"area": 1886068
}, {
	"name": "Suriname",
	"capital": "Paramaribo",
	"lat": "5.833333333",
	"lng": "-55.166667",
	"code": "SR",
	"continent": "South America",
	"calling_code": 597,
	"tld": ".sr",
	"landlocked": "0",
	"population": 575991,
	"area": 163265
}, {
	"name": "Svalbard",
	"capital": "Longyearbyen",
	"lat": "78.21666667",
	"lng": "15.633333",
	"code": "SJ",
	"continent": "Europe"
}, {
	"name": "Swaziland",
	"capital": "Mbabane",
	"lat": "-26.31666667",
	"lng": "31.133333",
	"code": "SZ",
	"continent": "Africa",
	"calling_code": 268,
	"tld": ".sz",
	"landlocked": "1",
	"population": 1136191,
	"area": 17364
}, {
	"name": "Sweden",
	"capital": "Stockholm",
	"lat": "59.33333333",
	"lng": "18.05",
	"code": "SE",
	"continent": "Europe",
	"calling_code": 46,
	"tld": ".se",
	"landlocked": "0",
	"population": 10175214,
	"area": 449964
}, {
	"name": "Switzerland",
	"capital": "Bern",
	"lat": "46.91666667",
	"lng": "7.466667",
	"code": "CH",
	"continent": "Europe",
	"calling_code": 41,
	"tld": ".ch",
	"landlocked": "1",
	"population": 8513227,
	"area": 41284
}, {
	"name": "Syria",
	"capital": "Damascus",
	"lat": "33.5",
	"lng": "36.3",
	"code": "SY",
	"continent": "Asia",
	"calling_code": 963,
	"tld": ".sy",
	"landlocked": "0",
	"population": 16906283,
	"area": 185180
}, {
	"name": "Taiwan",
	"capital": "Taipei",
	"lat": "25.03333333",
	"lng": "121.516667",
	"code": "TW",
	"continent": "Asia",
	"area": 36188
}, {
	"name": "Tajikistan",
	"capital": "Dushanbe",
	"lat": "38.55",
	"lng": "68.766667",
	"code": "TJ",
	"continent": "Asia",
	"calling_code": 992,
	"tld": ".tj",
	"landlocked": "1",
	"population": 9100837,
	"area": 143100
}, {
	"name": "Tanzania",
	"capital": "Dar es Salaam",
	"lat": "-6.8",
	"lng": "39.283333",
	"code": "TZ",
	"continent": "Africa",
	"calling_code": 255,
	"tld": ".tz",
	"landlocked": "0",
	"population": 56318348,
	"area": 883749
}, {
	"name": "Thailand",
	"capital": "Bangkok",
	"lat": "13.75",
	"lng": "100.516667",
	"code": "TH",
	"continent": "Asia",
	"calling_code": 66,
	"tld": ".th",
	"landlocked": "0",
	"population": 69428524,
	"area": 513115
}, {
	"name": "The Gambia",
	"capital": "Banjul",
	"lat": "13.45",
	"lng": "-16.566667",
	"code": "GM",
	"continent": "Africa"
}, {
	"name": "Timor-Leste",
	"capital": "Dili",
	"lat": "-8.583333333",
	"lng": "125.6",
	"code": "TL",
	"continent": "Asia"
}, {
	"name": "Togo",
	"capital": "Lome",
	"lat": "6.116666667",
	"lng": "1.216667",
	"code": "TG",
	"continent": "Africa",
	"calling_code": 228,
	"tld": ".tg",
	"landlocked": "0",
	"population": 7889094,
	"area": 56785
}, {
	"name": "Tokelau",
	"capital": "Atafu",
	"lat": "-9.166667",
	"lng": "-171.833333",
	"code": "TK",
	"continent": "Australia",
	"calling_code": 690,
	"tld": ".tk",
	"landlocked": "0",
	"population": 1411,
	"area": 12
}, {
	"name": "Tonga",
	"capital": "Nuku’alofa",
	"lat": "-21.13333333",
	"lng": "-175.2",
	"code": "TO",
	"continent": "Australia",
	"calling_code": 676,
	"tld": ".to",
	"landlocked": "0",
	"population": 103197,
	"area": 650
}, {
	"name": "Trinidad and Tobago",
	"capital": "Port of Spain",
	"lat": "10.65",
	"lng": "-61.516667",
	"code": "TT",
	"continent": "North America",
	"calling_code": 1868,
	"tld": ".tt",
	"landlocked": "0",
	"population": 1389858,
	"area": 5130
}, {
	"name": "Tunisia",
	"capital": "Tunis",
	"lat": "36.8",
	"lng": "10.183333",
	"code": "TN",
	"continent": "Africa",
	"calling_code": 216,
	"tld": ".tn",
	"landlocked": "0",
	"population": 11565204,
	"area": 163610
}, {
	"name": "Turkey",
	"capital": "Ankara",
	"lat": "39.93333333",
	"lng": "32.866667",
	"code": "TR",
	"continent": "Europe",
	"calling_code": 90,
	"tld": ".tr",
	"landlocked": "0",
	"population": 82319724,
	"area": 774815
}, {
	"name": "Turkmenistan",
	"capital": "Ashgabat",
	"lat": "37.95",
	"lng": "58.383333",
	"code": "TM",
	"continent": "Asia",
	"calling_code": 993,
	"tld": ".tm",
	"landlocked": "1",
	"population": 5850908,
	"area": 488100
}, {
	"name": "Turks and Caicos Islands",
	"capital": "Grand Turk",
	"lat": "21.46666667",
	"lng": "-71.133333",
	"code": "TC",
	"continent": "North America",
	"calling_code": 1649,
	"tld": ".tc",
	"landlocked": "0",
	"population": 37665,
	"area": 430
}, {
	"name": "Tuvalu",
	"capital": "Funafuti",
	"lat": "-8.516666667",
	"lng": "179.216667",
	"code": "TV",
	"continent": "Australia",
	"calling_code": 688,
	"tld": ".tv",
	"landlocked": "0",
	"population": 11508,
	"area": 26
}, {
	"name": "Uganda",
	"capital": "Kampala",
	"lat": "0.316666667",
	"lng": "32.55",
	"code": "UG",
	"continent": "Africa",
	"calling_code": 256,
	"tld": ".ug",
	"landlocked": "1",
	"population": 42723139,
	"area": 241038
}, {
	"name": "Ukraine",
	"capital": "Kyiv",
	"lat": "50.43333333",
	"lng": "30.516667",
	"code": "UA",
	"continent": "Europe",
	"calling_code": 380,
	"tld": ".ua",
	"landlocked": "0",
	"population": 44622516,
	"area": 603700
}, {
	"name": "United Arab Emirates",
	"capital": "Abu Dhabi",
	"lat": "24.46666667",
	"lng": "54.366667",
	"code": "AE",
	"continent": "Asia",
	"calling_code": 971,
	"tld": ".ae",
	"landlocked": "0",
	"population": 9630959,
	"area": 83600
}, {
	"name": "United Kingdom",
	"capital": "London",
	"lat": "51.5",
	"lng": "-0.083333",
	"code": "GB",
	"continent": "Europe",
	"calling_code": 44,
	"tld": ".gb",
	"landlocked": "0",
	"population": 66460344,
	"area": 242900
}, {
	"name": "United States",
	"capital": "Washington, D.C.",
	"lat": "38.883333",
	"lng": "-77",
	"code": "US",
	"continent": "Central America",
	"calling_code": 1,
	"tld": ".us",
	"landlocked": "0",
	"population": 326687501,
	"area": 9363520
}, {
	"name": "Uruguay",
	"capital": "Montevideo",
	"lat": "-34.85",
	"lng": "-56.166667",
	"code": "UY",
	"continent": "South America",
	"calling_code": 598,
	"tld": ".uy",
	"landlocked": "0",
	"population": 3449299,
	"area": 175016
}, {
	"name": "US Minor Outlying Islands",
	"capital": "Washington, D.C.",
	"lat": "38.883333",
	"lng": "-77",
	"code": "UM",
	"continent": "Australia"
}, {
	"name": "US Virgin Islands",
	"capital": "Charlotte Amalie",
	"lat": "18.35",
	"lng": "-64.933333",
	"code": "VI",
	"continent": "North America"
}, {
	"name": "Uzbekistan",
	"capital": "Tashkent",
	"lat": "41.31666667",
	"lng": "69.25",
	"code": "UZ",
	"continent": "Asia",
	"calling_code": 998,
	"tld": ".uz",
	"landlocked": "1",
	"population": 32955400,
	"area": 447400
}, {
	"name": "Vanuatu",
	"capital": "Port-Vila",
	"lat": "-17.73333333",
	"lng": "168.316667",
	"code": "VU",
	"continent": "Australia",
	"calling_code": 678,
	"tld": ".vu",
	"landlocked": "0",
	"population": 292680,
	"area": 12189
}, {
	"name": "Vatican City",
	"capital": "Vatican City",
	"lat": "41.9",
	"lng": "12.45",
	"code": "VA",
	"continent": "Europe"
}, {
	"name": "Venezuela",
	"capital": "Caracas",
	"lat": "10.48333333",
	"lng": "-66.866667",
	"code": "VE",
	"continent": "South America",
	"calling_code": 58,
	"tld": ".ve",
	"landlocked": "0",
	"population": 28870195,
	"area": 912050
}, {
	"name": "Vietnam",
	"capital": "Hanoi",
	"lat": "21.03333333",
	"lng": "105.85",
	"code": "VN",
	"continent": "Asia",
	"calling_code": 84,
	"tld": ".vn",
	"landlocked": "0",
	"population": 95540395,
	"area": 331689
}, {
	"name": "Wallis and Futuna",
	"capital": "Mata-Utu",
	"lat": "-13.95",
	"lng": "-171.933333",
	"code": "WF",
	"continent": "Australia",
	"calling_code": 681,
	"tld": ".wf",
	"landlocked": "0",
	"population": 15289,
	"area": 200
}, {
	"name": "Western Sahara",
	"capital": "El-AaiÃºn",
	"lat": "27.153611",
	"lng": "-13.203333",
	"code": "EH",
	"continent": "Africa",
	"calling_code": null,
	"tld": ".eh",
	"landlocked": "0",
	"population": 652271,
	"area": 266000
}, {
	"name": "Yemen",
	"capital": "Sanaa",
	"lat": "15.35",
	"lng": "44.2",
	"code": "YE",
	"continent": "Asia",
	"calling_code": 967,
	"tld": ".ye",
	"landlocked": "0",
	"population": 28498687,
	"area": 527968
}, {
	"name": "Zambia",
	"capital": "Lusaka",
	"lat": "-15.41666667",
	"lng": "28.283333",
	"code": "ZM",
	"continent": "Africa",
	"calling_code": 260,
	"tld": ".zm",
	"landlocked": "1",
	"population": 17351822,
	"area": 752618
}, {
	"name": "Zimbabwe",
	"capital": "Harare",
	"lat": "-17.81666667",
	"lng": "31.033333",
	"code": "ZW",
	"continent": "Africa",
	"calling_code": 263,
	"tld": ".zw",
	"landlocked": "1",
	"population": 14439018,
	"area": 390757
}];